<template>
  <router-view />
</template>

<script>
import conversionModule from '../../store/conversions'

export default {
  beforeCreate () {
    this.$store.registerModule('conversions', conversionModule)
  },
  destroyed () {
    this.$store.unregisterModule('conversions')
  }
}
</script>

import {
  CONVERSION_FETCH_REQUEST, CONVERSION_FETCH_SUCCESS, CONVERSION_FETCH_FAILURE
} from './types'

export default {
  [CONVERSION_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [CONVERSION_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [CONVERSION_FETCH_FAILURE] (state) {
    state.loading = false
  },
}
